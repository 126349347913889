.Input {
  display: inline-block;
  color: #44444f;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0.1px;
  outline: none;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Remove Arrows/Spinners for input with type="number"  */

Input::-webkit-outer-spin-button,
Input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
Input[type="number"] {
  -moz-appearance: textfield;
}

.Input::placeholder {
  color: #b5b5be;
}

.Input--standard {
  border: none;
  border-radius: unset;
  border-bottom: 1px solid #e2e2ea;
  padding: 0 0 10px 0;
}

.Input--outlined {
  border: 1px solid #e2e2ea;
  padding: 16px 15px;
  border-radius: 10px;
}

/*
  Hover state
*/

.Input--standard:hover {
  border-bottom: 1px solid #a09ae0;
}

.Input--outlined:hover {
  border: 1px solid #a09ae0;
}

/*
  Focus state
*/

.Input--standard:focus {
  border-bottom: 1px solid #766ed7;
}

.Input--outlined:focus {
  border: 1px solid #766ed7;
}

/*
  Error state
*/

.Input--standard.Input--error {
  border-bottom: 1px solid #fc5a5a;
}

.Input--outlined.Input--error {
  border: 1px solid #fc5a5a;
}
