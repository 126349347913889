.App {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.App__section {
  width: 100%;
  height: 449px;
  background: #e7ebff;
  opacity: 0.9;
  padding: 40px 32px;
  background-image: url(./assets/saly.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 212px 286px;
}

.App__content {
  display: flex;
  flex: 1;
  padding: 12px;
  justify-content: center;
}

.App__wrapper {
  max-width: 446px;
  width: 100%;
}

.App__form {
  margin-top: 46px;
}

.AppNoId {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.AppNoId__topHalf {
  width: 100vw;
  height: 50%;
  min-height: 400px;
  justify-content: center;
  display: flex;
  background: none;
  margin-bottom: 90px;
}

.AppNoId__topHalf img {
  max-width: 100%;
  min-height: 400px;
  max-height: 50vh;
  margin-top: 50px;
}

.App__logo {
  position: absolute;
  left: 5%;
  top: 30px;
}

@media (max-width: 768px) {
  .AppNoId__topHalf {
    margin-bottom: 30px;
  }
  .AppNoId__topHalf img {
    max-width: 100%;
    min-height: auto;
    margin-top: 60px;
  }
  .AppNoId__painted {
    height: 40vh !important;
  }
}

.AppNoId__painted {
  width: 100vw;
  height: calc(40vh + 130px);
  min-height: 330px;
  max-height: 430px;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #E7EBFF;
  z-index: -1;
}

.AppNoId__bottomHalf {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AppNoId__bottomHalf div {
  text-align: center;
  margin-bottom: 40px;
  color: #270F56;
  font-size: 24px;
}

.AppNoId__bottomHalf button {
  border-radius: 4px;
  width: 263px;
  height: 45px;
  font-size: 14px;
}

@media screen and (min-width: 768px) {
  .App {
    flex-direction: row;
  }

  .App__content {
    justify-content: initial;
    padding: 59px;
  }

  .App__section {
    width: 34%;
    height: 100%;
    background-size: auto;
  }
}
