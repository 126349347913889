.JobApplication {
  display: flex;
  flex-direction: column;
}

.JobApplication__title {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #000000;
}

.JobApplication__description {
  font-family: "Lato", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #8692a6;
  margin-top: 24px;
}

.JobApplication__description--bold {
  font-weight: bold;
}

.JobApplication__fields {
  margin-top: 40px;
}

.JobApplication__fields > *:not(:first-child) {
  margin-top: 24px;
}

.JobApplication__bottom-line {
  height: 4px;
  background: #f1f1f5;
  border-radius: 3px;
  margin: 30px 0 32px 0;
}

.JobApplication__button-area {
  display: flex;
  justify-content: space-between;
}

.JobApplication__button-area-left {
  text-align: left;
}

.JobApplication__button-area-right {
  text-align: right;
}

.JobApplication__submit-button {
  width: fit-content;
  align-self: flex-end;
}
